import Vue from 'vue'
import VueRouter from 'vue-router'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {layout: 'main' , redirect: true},
    component: () => import('../views/hub/List.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'empty'},
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/settings/forgeAPI',
    name: 'forgeAPISetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/ForgeAPI.vue'),
  },
  {
    path: '/settings/notify',
    name: 'notifySetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/Notify.vue'),
  },
  {
    path: '/settings/project',
    name: 'projectSetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/Project.vue'),
  },
  {
    path: '/settings/user',
    name: 'userSetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/User.vue'),
  },
  {
    path: '/settings/support',
    name: 'supportSetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/Support.vue'),
  },
  {
    path: '/settings/faq',
    name: 'faqSetting',
    meta: {layout: 'Setting'},
    component: () => import('../views/settings/FAQ.vue'),
  },
  {
    path: '/list',
    name: 'list',
    meta: {layout: 'main'},
    component: () => import('../views/hub/List.vue'),
  },
  {
    path: '/list/:folderId-:hubId',
    name: 'folder',
    meta: {layout: 'main'},
    component: () => import('../views/hub/List.vue'),
    props: (route) => ({
      folderId: route.params.folderId,
      hubId: route.params.hubId,
    }),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueYandexMetrika, {
  id: 90207304,
  router: router,
  env: process.env.NODE_ENV
  // other options
})

router.beforeEach((to , from , next) => {
  const requireAuth = to.matched.some(record => record.meta.redirect)
  if (requireAuth) {
    next('/list')
  } else {
    next()
  }
});


export default router
